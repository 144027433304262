import React, { useEffect } from "react";
import { getMsalInstance, changeActiveRoute } from '@bayshoreHealthCare/store';
import { EventType } from "@azure/msal-browser";
import App from "./App";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter, Route, RouterProvider, Routes, createBrowserRouter } from "react-router-dom";
import { SwitchableFluentThemeProvider } from "./theming/SwitchableFluentThemeProvider";
const msalInstance = getMsalInstance();

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: any) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
	}
});

export default function Root(props) {

	useEffect(() => {
		changeActiveRoute({ activePage: 'Video Calling App' });
	}, []);

	return (
		<React.StrictMode>
			<SwitchableFluentThemeProvider scopeId="SampleCallingApp">
				<MsalProvider instance={msalInstance}>
					<BrowserRouter basename="/call">
						<Routes>
							<Route path="/" element={<App />} />
						</Routes>
					</BrowserRouter>
				</MsalProvider>
			</SwitchableFluentThemeProvider>
		</React.StrictMode>
	)
}

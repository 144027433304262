import { CommunicationUserIdentifier } from '@azure/communication-common';
import {
	AzureCommunicationCallAdapterArgs,
	fromFlatCommunicationIdentifier,
	onResolveVideoEffectDependencyLazy,
	toFlatCommunicationIdentifier,
	useAzureCommunicationCallAdapter
} from '@azure/communication-react';
import { initializeIcons } from '@fluentui/react';
import React, { useMemo } from 'react';
import './style.css';
import { createAutoRefreshingCredential } from '../../utils/acsCredentials';
import { assetUrl } from '../../utils/asset-url';
import MessageScreen from '../MessageScreen';
import { CallCompositeContainer } from '../CallCompositeContainer';

initializeIcons();

export interface CallScreenProps {
	isGuestUser: boolean;
	user: CommunicationUserIdentifier;
	token: string;
	displayName: string;
	roomId: string;
}

function CallScreen(props: CallScreenProps): JSX.Element {
	// Arguments that would usually be provided by your backend service or
	// (indirectly) by the user.
	const { user, token, displayName, roomId } = props;

	// Memoize arguments to `useAzureCommunicationCallAdapter` so that
	// a new adapter is only created when an argument changes.
	const callAdapterArgs = useMemo(
		(): AzureCommunicationCallAdapterArgs => {
			const credential = createAutoRefreshingCredential(toFlatCommunicationIdentifier(user), token);
			return {
				userId: fromFlatCommunicationIdentifier(user.communicationUserId) as CommunicationUserIdentifier,
				displayName,
				credential,
				locator: { roomId },
				options: {
					videoBackgroundOptions: {
						videoBackgroundImages: videoBackgroundImages.map(img => ({...img, url: assetUrl(img.url)})),
						onResolveDependency: onResolveVideoEffectDependencyLazy,
					},
				}
			};
		},
		[user, displayName, roomId]
	);
	const callAdapter = useAzureCommunicationCallAdapter(callAdapterArgs);

	if (!!callAdapter) {
		return <CallCompositeContainer {...props} adapter={callAdapter} />;
	}
	// if (credential === undefined) {
	// 	return <h3>Failed to construct credential. Provided token is malformed.</h3>;
	// }
	return <MessageScreen message='Connecting call...' />;
}

const videoBackgroundImages = [
	{
		key: 'ab1',
		url: '/backgrounds/contoso.png',
		tooltipText: 'Custom Background'
	},
	{
		key: 'ab2',
		url: '/backgrounds/abstract2.jpg',
		tooltipText: 'Custom Background'
	},
	{
		key: 'ab3',
		url: '/backgrounds/abstract3.jpg',
		tooltipText: 'Custom Background'
	},
	{
		key: 'ab4',
		url: '/backgrounds/room1.jpg',
		tooltipText: 'Custom Background'
	},
	{
		key: 'ab5',
		url: '/backgrounds/room2.jpg',
		tooltipText: 'Custom Background'
	},
	{
		key: 'ab6',
		url: '/backgrounds/room3.jpg',
		tooltipText: 'Custom Background'
	},
	{
		key: 'ab7',
		url: '/backgrounds/room4.jpg',
		tooltipText: 'Custom Background'
	}
];

export default CallScreen;

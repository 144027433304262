// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#call-section {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#guest-section {
    height: calc(100vh - 50px);
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,0BAA0B;IAC1B,WAAW;AACf","sourcesContent":["#call-section {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n#guest-section {\n    height: calc(100vh - 50px);\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

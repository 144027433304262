import { useMsal } from '@azure/msal-react';
import './app.css';
import CallScreen from "./components/CallScreen";
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CommunicationUserIdentifier } from '@azure/communication-common';
import { getAcsUserAndToken } from './utils/acsApi';
import { GuestUserScreen } from './components/GuestUserScreen';
import MessageScreen from './components/MessageScreen';

export default function App() {

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const [token, setToken] = useState<string>();
    const [user, setUser] = useState<CommunicationUserIdentifier>();
    const [displayName, setDisplayName] = useState('');
    const [message, setMessage] = useState('Loading...');

    const [searchParams] = useSearchParams();
    const roomId = searchParams.get("roomId");

    const isGuestUser = useMemo(() => {
        return !displayName;
    }, [displayName]);

    useEffect(() => {
        if (activeAccount && activeAccount.idTokenClaims) {
            const userProfile: any = activeAccount.idTokenClaims;
            setDisplayName(`${userProfile.given_name ?? ""} ${userProfile.family_name ?? ""}`);
        }
        setMessage('');
    }, [activeAccount]);

    useEffect(() => {
        if (!(roomId && displayName)) return;
        getAcsUserAndToken(roomId, displayName, !activeAccount)
        .then(result => {
            setToken(result.token);
            setUser(result.user);
        }).catch(err => {
            setMessage(err.message || (typeof err === 'object' ? JSON.stringify(err) : err));
        })
    }, [roomId, displayName]);

    if (message !== '') {
        return <MessageScreen message={message} />;
    }

    if (isGuestUser) {
        return (
            <section id="guest-section">
                <GuestUserScreen nextButtonHandle={setDisplayName} />
            </section>
        );
    
    }

    if (!user) {
        return <MessageScreen message='Connecting call...' />;  
    }

    return (
        <div id="call-section">
            <CallScreen displayName={displayName} user={user} token={token} roomId={roomId} isGuestUser={!activeAccount} />
        </div>
    );
}
import axios from 'axios';
import { getMsalInstance, onOrganizationChange$ } from '@bayshoreHealthCare/store';
import { SilentRequest } from '@azure/msal-browser';
import { getActiveOrganizationFromLocalStorage } from '../utils/localStorage';
const msalInstance = getMsalInstance();

const acsApi = axios.create({
  baseURL: process.env.ACS_API_URL,
});

acsApi.interceptors.request.use(
  async (config) => {
    const activeAccount = msalInstance.getActiveAccount();
    if (activeAccount) {
      const { idToken: token } = await msalInstance.acquireTokenSilent({ account: activeAccount } as SilentRequest);
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    const activeOrg = getActiveOrganizationFromLocalStorage();
    if (activeOrg.length === 3) {
        config.headers['activeProfile'] = activeOrg[0];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default acsApi;
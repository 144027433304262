import React from 'react';
import { containerStyle, containerTokens, infoStyle } from '../GuestUserScreen/GuestScreen.styles';
import { Stack, Text } from '@fluentui/react';

const MessageScreen: React.FC<{ message: string }> = (props) => {
    const { message } = props;
    return (
        <section style={{ height: 'calc(100vh - 78px)', display: 'flex' }}>
            <Stack
                horizontal
                wrap
                horizontalAlign="center"
                verticalAlign="center"
                className={containerStyle}
            >
                <Text role={'log'} aria-level={1} className={infoStyle}>
                    {message}
                </Text>
            </Stack>
        </section>
    );
};

export default MessageScreen;
import acsApi from "../config/acs-api";
import { AcsUserToken } from "../interfaces/acs";

/**
 * Get ACS User with access token to join ACS room call 
 * @returns 
 */
export async function getAcsUserAndToken(roomId: string, displayName: string, isGuestUser: boolean): Promise<AcsUserToken>{
    const userId = localStorage.getItem('ACS_USER_ID');
    const respoonse = await acsApi.get<AcsUserToken>(`/call?roomId=${roomId}&displayName=${displayName}${userId && isGuestUser ? `&userId=${userId}` : ''}`);
    if (respoonse.status !== 200) {
        throw new Error(respoonse.statusText);
    }
    const acsUserId = respoonse.data.user.communicationUserId;
    localStorage.setItem('ACS_USER_ID', acsUserId);
    return respoonse.data;
}